//Multilanguage Button
document.addEventListener('DOMContentLoaded', function() {
  const customNavLinks = document.querySelectorAll('.custom-nav-link');

  customNavLinks.forEach(function(link) {
    link.addEventListener('click', function(e) {
      e.preventDefault();

      // Remove active class from all language links
      customNavLinks.forEach(function(navLink) {
        navLink.classList.remove('active-lang');
      });

      // Add active class to the clicked language link
      this.classList.add('active-lang');

      // Update language based on the clicked link
      const lang = this.id === 'enLink' ? 'en' : 'pt';
      updateLanguage(lang);
    });
  });

  // Function to update the language in the URL
  function updateLanguage(lang) {
    const currentPath = window.location.pathname;
    let newPath;

    if (currentPath.startsWith('/en/')) {
      newPath = lang === 'en' ? currentPath : currentPath.replace('/en/', '/');
    } else {
      newPath = lang === 'pt' ? currentPath : `/${lang}${currentPath}`;
    }

    window.location.href = newPath;
  }

  // Set initial active language link based on the current page
  const currentPath = window.location.pathname;
  const enLink = document.getElementById('enLink');
  const ptLink = document.getElementById('ptLink');

  if (currentPath.startsWith('/en/')) {
    enLink.classList.add('active-lang');
  } else {
    ptLink.classList.add('active-lang');
  }
});



//Form.js
document.addEventListener('DOMContentLoaded', function() {
  const rootForm = document.getElementById('rootForm');
  const enForm = document.getElementById('enForm');
  const responseMessage = document.getElementById('responseMessage');

  const phpScriptURL = 'https://jardinsdafalesia.com/contact.php'; 

  function submitForm(form) {
      const formData = new FormData(form);
      const xhr = new XMLHttpRequest();

      xhr.open('POST', phpScriptURL, true);

      xhr.onreadystatechange = function() {
          if (xhr.readyState === XMLHttpRequest.DONE) {
              if (xhr.status === 200) {
                  responseMessage.innerHTML = xhr.responseText;
              } else {
                  responseMessage.innerHTML = 'An error occurred. Please try again.';
              }
          }
      };

      xhr.send(formData);
  }

  if (rootForm) {
      rootForm.addEventListener('submit', function(event) {
          event.preventDefault(); 
          submitForm(rootForm);
      });
  }

  if (enForm) {
      enForm.addEventListener('submit', function(event) {
          event.preventDefault(); 
          submitForm(enForm);
      });
  }
});